import { AddCircle, ArrowDropDown, ArrowRight } from '@mui/icons-material'
import {
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
  styled
} from '@mui/material'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useBucketPlan from '../../../hooks/useBucketPlan'
import CreateAlbumForm from './CreateAlbumForm'
import useBucket from '../../../hooks/useBucket'
import useBucketMigrationProgress from '../../../hooks/useBucketMigrationProgress'

interface Props {
  isOpen?: boolean
  hasAlbums?: boolean
  toggle?: () => void
}

export const AlbumLink = styled(Button)(({ theme }) => ({
  display: 'block',
  width: '100%',
  fontSize: '1rem',
  '&:hover': { color: theme.palette.primary.main },
  textAlign: 'left',
  color: 'inherit'
}))

export default function AlbumSubHeader({ isOpen, hasAlbums, toggle }: Props) {
  const { bucketUser } = useBucket()
  const { restrictions, tooltipTitle } = useBucketPlan()
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const { bucketId } = useParams<{ bucketId: string }>()
  const isMigrationInProgressForBucket = useBucketMigrationProgress()

  const restricted = restrictions.modifyAlbums
  const disabled = !bucketUser?.permissions?.albumCreate
  const hideTooltip = restricted === false && disabled === false
  let title = tooltipTitle('modifyAlbums')
  if (isMigrationInProgressForBucket) {
    title = 'Album create is disabled during the migration process'
  } else if (restricted) {
    title = 'Your viewer role for this bucket does not allow you to create albums.'
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onClickAlbum = () => {
    navigate(`/bucket/${bucketId}/albums`)
  }

  if (!bucketId) {
    return null
  }

  return (
    <>
      <ListItem disablePadding>
        {toggle && (
          <IconButton aria-label={isOpen ? 'Expand' : 'Collapse'} onClick={toggle} disableRipple color="primary" sx={{ padding: 0 }}>
            {isOpen ? <ArrowDropDown /> : <ArrowRight />}
          </IconButton>
        )}
        <ListItemText
          primary={
            <AlbumLink onClick={onClickAlbum} data-test="drawer-albums-title-btn">
              Albums
            </AlbumLink>
          }
        />
        <Tooltip
          PopperProps={{
            disablePortal: hideTooltip
          }}
          disableFocusListener={hideTooltip}
          disableHoverListener={hideTooltip}
          disableTouchListener={hideTooltip}
          title={title}
        >
          <ListItemSecondaryAction sx={{ right: 0 }}>
            <Collapse in={!open}>
              <IconButton
                disabled={disabled || restricted || isMigrationInProgressForBucket}
                onClick={handleOpen}
                data-testid="create-album-action-button"
                size="small"
                color="primary"
              >
                <AddCircle fontSize="small" />
              </IconButton>
            </Collapse>
          </ListItemSecondaryAction>
        </Tooltip>
      </ListItem>
      <Tooltip
        PopperProps={{
          disablePortal: hideTooltip
        }}
        disableFocusListener={hideTooltip}
        disableHoverListener={hideTooltip}
        disableTouchListener={hideTooltip}
        title={title}
      >
        <div>
          {!hasAlbums && !isMigrationInProgressForBucket && (
            <Button
              disabled={disabled || restricted}
              variant="contained"
              color="primary"
              onClick={handleOpen}
              sx={{ display: 'block', width: '100%' }}
            >
              Create Album
            </Button>
          )}
        </div>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ flex: 1 }}>
            Create Album
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ padding: 3, paddingTop: 0 }}>
          <CreateAlbumForm bucketId={bucketId} handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </>
  )
}

AlbumSubHeader.defaultProps = {
  isOpen: false,
  hasAlbums: false,
  toggle: undefined
}
