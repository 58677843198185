import { useQuery } from '@apollo/client'
import { debounce } from 'lodash'
import { useParams } from 'react-router-dom'
import { BucketSharesDocument } from '../../../graphql/generated'
import logger from '../../../utils/logger'
import useAlerts from './useAlerts'

export default function useBucketShares() {
  const { createAlert } = useAlerts()
  const { bucketId } = useParams<{ bucketId: string }>()

  const results = useQuery(BucketSharesDocument, {
    skip: !bucketId,
    variables: { bucketId: bucketId! },
    onError: (error) => {
      if (error.graphQLErrors.length) {
        error.graphQLErrors.forEach((e) => {
          createAlert(e.message, 'error', e)
        })
        return
      }

      createAlert('There was a problem retrieving your bucket shares. Please try again later.')
      logger.error(error)
    }
  })

  const items = results.data?.bucketShares?.items ?? []
  const nextToken = results.data?.bucketShares?.nextToken
  const loading = !results.called || results.loading

  const func = () => {
    if (nextToken) {
      results.fetchMore({ variables: { nextToken } })
    }
  }
  const fetchMore = debounce(func, 500, { leading: true })

  return { items, nextToken, loading, fetchMore }
}
