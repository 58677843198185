import { useQuery } from '@apollo/client'
import AccountCircle from '@mui/icons-material/AccountCircle'
import { styled } from '@mui/material'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { getAuth, signOut } from 'firebase/auth'
import { MouseEvent, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Cookies from 'universal-cookie/es6'
import config, { superUglyHackToClearAllCookies } from '../../../../config'
import { BucketFragmentFragmentDoc, BucketsByUserIdDocument } from '../../../../graphql/generated'
import cache from '../../../../providers/apollo/cache'
import logger from '../../../../utils/logger'
import useBucketRedirect from '../../hooks/useBucketRedirect'

interface Props {
  anchorEl: null | HTMLElement
  handleClose(): void
  bucketId: string | null | undefined
}

const CustomButton = styled(Button)(() => ({
  width: 30,
  height: 30,
  padding: 5,
  borderRadius: '50%',
  fontWeight: 800,
  minWidth: 'inherit',
  background: 'linear-gradient(153.66deg, #1C47CB -15.36%, #02E39F 105.31%)',
  color: '#fff',
  margin: '0 5px',
  '&:hover': {
    background: 'linear-gradient(153.66deg, #1C47CB -15.36%, #1C47CB 105.31%)'
  }
}))

function AuthenticatedDropDown({ bucketId, anchorEl, handleClose }: Props) {
  const location = useLocation()
  const navigate = useNavigate()
  const { emailVerified } = getAuth().currentUser ?? {}
  const bucket = cache.readFragment({ fragment: BucketFragmentFragmentDoc, id: `Bucket:${bucketId}` })
  const title = bucket?.title ?? 'My Bucket'
  const { redirectToBucket } = useBucketRedirect()
  const { pathname } = location
  const isSubcriptionPath = pathname.includes('/subscription')

  const handleLogout = async () => {
    try {
      const auth = getAuth()
      await signOut(auth)
    } catch (error) {
      logger.error(error)
    }

    const cookies = new Cookies()
    cookies.remove('app_auth', config.cookieOptions)
    superUglyHackToClearAllCookies.forEach((cookieConfig) => cookies.remove('app_auth', cookieConfig))
    cookies.remove('facebook_dtp', config.cookieOptions)
    superUglyHackToClearAllCookies.forEach((cookieConfig) => cookies.remove('facebook_dtp', cookieConfig))
    localStorage.removeItem('reduxPersist:auth')
    localStorage.removeItem('_pb_userData:username')
    handleClose()
    window.location.href = '/?logout=true'
  }

  const handleClick = () => {
    if (!bucketId) {
      navigate('/')
      return
    }
    redirectToBucket(bucketId)
    handleClose()
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
      {!isSubcriptionPath && (
        <MenuItem onClick={handleClick} data-test="appbar-dropdown-my-bucket" id="appbar-dropdown-my-bucket">
          {title}
        </MenuItem>
      )}
      {(emailVerified || !isSubcriptionPath) && (
        <MenuItem
          component={Link}
          to="/settings#profile"
          onClick={handleClose}
          data-test="appbar-dropdown-change-plan"
          id="appbar-dropdown-change-plan"
        >
          Settings
        </MenuItem>
      )}
      <MenuItem onClick={handleLogout} data-test="appbar-dropdown-logout" id="appbar-dropdown-logout">
        Logout
      </MenuItem>
    </Menu>
  )
}

function getInitials(name: string) {
  const splitName = name.split(' ')
  try {
    if (splitName.length === 0) {
      return ''
    }
    if (splitName.length === 1) {
      return splitName[0][0]
    }
    const first = splitName[0][0]
    const last = splitName[splitName.length - 1][0]
    return [first, last].join('')
  } catch (error) {
    return null
  }
}

export default function ProfileMenu() {
  const userId = getAuth().currentUser?.uid
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const queryParams = useParams<{ bucketId: string }>()
  const results = useQuery(BucketsByUserIdDocument, {
    skip: !userId,
    variables: { userId: userId! },
    onError: (e) => {
      logger.error(e)
    }
  })

  const isLoading = results?.loading || !results.called
  const currentBucketId = queryParams.bucketId ?? results.data?.userProfile?.currentBucketId
  const name = results.data?.userProfile?.name ?? 'My Account'
  const initials = getInitials(name)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // Render initials only if not loading and initials are available
  if (!isLoading && initials) {
    return (
      <>
        <CustomButton onClick={handleClick} data-test="profile-dropdown-toggle" id="profile-dropdown-toggle">
          {initials}
        </CustomButton>
        <AuthenticatedDropDown handleClose={handleClose} bucketId={currentBucketId} anchorEl={anchorEl} />
      </>
    )
  }

  return (
    <>
      <IconButton
        sx={{
          padding: '4px',
          '& svg': {
            fontSize: '32px'
          }
        }}
        color="inherit"
        aria-label="Profile menu"
        onClick={handleClick}
        data-test="profile-dropdown-toggle"
        id="profile-dropdown-toggle"
        size="large"
      >
        <AccountCircle />
      </IconButton>
      <AuthenticatedDropDown handleClose={handleClose} bucketId={currentBucketId} anchorEl={anchorEl} />
    </>
  )
}
