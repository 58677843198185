import React from 'react'
import logger from '../../utils/logger'
import { RumContext } from './RumProvider'

export default function useRum() {
  const rum = React.useContext(RumContext)

  const recordError = (error: ErrorEvent) => {
    if (!rum) {
      logger.warn('recordError: RUM is not enabled')
      return
    }
    rum.recordError(error)
  }

  const recordEvent = (eventType: string, eventData: object) => {
    if (!rum) {
      logger.warn('recordEvent: RUM is not enabled')
      return
    }
    rum.recordEvent(eventType, eventData)
  }

  const addSessionAttributes = (sessionAttributes: { [key: string]: string | boolean | number }) => {
    if (!rum) {
      logger.warn('addSessionAttributes: RUM is not enabled')
      return
    }
    rum.addSessionAttributes(sessionAttributes)
  }

  const generateErrorEvent = (type: string, additionalDetails: object | string) => {
    const message = typeof additionalDetails === 'string' ? additionalDetails : JSON.stringify(additionalDetails)
    return new ErrorEvent(type, { message })
  }

  return { addSessionAttributes, generateErrorEvent, recordError, recordEvent }
}
